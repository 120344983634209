/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* familiar screen */

.familiar-container {
    /* margin-top: 3rem; */
}

.familiar-screen-text {
    margin-top: 3rem;
    margin-left: 4.5rem;
    margin-bottom: 6rem;;
}

.select-familiar-button {
    background-color: #A8E3F0;
    border:none;
    border-radius: 24px;
    padding: 1.2rem 3rem;
    min-width:220px
}

.familiar-row {
    margin-bottom: 3rem;
    /* align-items: center;s */
}

.familiar-col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:2rem;
}

.next-button {
    background-color: #68BCEC;
    border:none;
    border-radius: 24px;
    padding: 1.2rem 3rem;
    min-width:220px;
    /* position: absolute;
    right: 100px;
    bottom: 0; */
}

.next-button-container {
    position: fixed;
    bottom: 3rem;
    right: 3rem;
}

.selected {
    background-color: #68BCEC;
}

/* .familiar-main-div {
    position:relative;
    height: 90vh;
} */

/* login form screen */

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}


.signup-button {
    background-color: #D6E0FA;
    color: black;
    border: none;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 0.6rem;
    margin: 1rem;
    padding: 0.8rem;
    min-width: 250px;
    min-height: 80px;
}

.signup-button:hover {
    border: 1px solid #D2CCCC;
}

.signup-icon-linkdin {
    color: #0A66C2;
    margin-right: 0.7rem;
}

.signup-icon-google {
    color: #0A66C2;
    margin-right: 0.7rem;
}

/* sign up form screen */

.form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
}

.form-button {
    margin-top: 1rem;
}

/* signup screen 1 */

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}


.signup-button {
    background-color: #D6E0FA;
    color: black;
    border: none;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 0.6rem;
    margin: 1rem;
    padding: 0.8rem;
    min-width: 250px;
    min-height: 80px;
}

.signup-button:hover {
    border: 1px solid #D2CCCC;
}

.signup-icon-linkdin {
    color: #0A66C2;
    margin-right: 0.7rem;
    /* display: inline-block; */
    /* padding: 0.4rem; */
}

.signup-icon-google {
    color: #0A66C2;
    margin-right: 0.7rem;
    /* display: inline-block; */
    /* padding: 0.4rem; */
}

/* sign up screen 2 */


.page-container {
    margin-top: 2rem;
    /* margin-left: 2rem; */
    /* font-size: 21px; */
    /* margin-right: 2rem; */
}

.profile-row {
    
}

.profile-image {
    width: 124px;
    height: 130px;
    border-radius: 60px;
}

.congo-text {
    margin-top: 2rem;
    font-weight: 700;
    font-size: 21px;
}

.launguage-proficiency {
    margin-top: 2rem;
}

.select-options {
    margin-top: 1.4rem;
}

.playbook-modal > .modal-dialog {
    max-width: 90% !important; 
}

.rate-button {
    min-width: 250px;   
    padding: 15px;
    background-color: #90CE96;
    border-radius: .5rem;
    border: none;
    max-height: 100px;
    color: white !important;
    font-weight: 400;
}

.rate-button:hover {
    border: 0.8px solid #2c9654;
    background-color: green
}

a {
    color: rgb(51,102,187) !important;
}